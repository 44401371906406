<template>
  <div>
    <div class="z-0">
      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <a
          href="/search/Trending"
          class="text-xl font-bold xl:text-xl text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-purple-500 to-blue-500 transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer text-shadow-white"
        >
          Trending Prompts

          <b class="text-sm text-gray-200"> Explore All </b>
          >
        </a>
        <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />

        <div class="grid grid-cols-2 gap-2 lg:grid-cols-6 md:grid-cols-3">
          <div
            class="transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer rounded-sm rounded"
            style="margin-top: 5px"
            v-for="s in prompts"
            :key="s"
          >
            <div
              class="p-0.5 bg-opacity-25 bg-gradient-to-r from-purple-500 via-pink-600 to-purple-300 rounded"
            >
              <div class="text-sm border-none rounded bg-gray-900">
                <a class="" :href="linkDetail(s)">
                  <img
                    :src="s.img_1"
                    alt="Image 1"
                    class="inset-0 object-cover ease-in-out opacity-100 hover:opacity-0 h-44 md:h-48 lg:h-60 w-full"
                  />

                  <img
                    :src="s.img_2"
                    style="height: 15.3rem"
                    alt="Image 2"
                    class="absolute inset-0 object-cover transition duration-500 ease-in-out opacity-0 hover:opacity-100 h-44 md:h-48 lg:h-60 w-full"
                  />

                  <!-- <img :src="s.img_1" /> -->

                  <div class="flex -mt-7 ml-2">
                    <div class="flex-none">
                      <div
                        class="flex border rounded bg-blue-900 bg-opacity-75"
                      >
                        <div class="text-xs p-1 text-gray-200">
                          <i class="fa fa-check-square text-gray-200"></i>
                          {{ s.prompt_type }}
                        </div>
                      </div>
                    </div>

                    <div
                      class="flex-grow text-right text-gray-200 mr-2 hidden lg:block"
                    >
                      <h3 class="text-sm font-bold">
                        <i class="fas fa-eye"></i>

                        {{ s.view }}

                        <i class="fas fa-heart ml-2"></i>

                        {{ s.favorite }}
                      </h3>
                    </div>
                  </div>

                  <div class="flex"></div>

                  <div class="flex bg-black pt-1">
                    <div class="flex-none">
                      <h4 class="text-xs p-2 mt-1 text-gray-200">
                        ⛵ {{ s.title.slice(0, 12) }} ...
                      </h4>
                    </div>

                    <div class="flex-grow p-2 text-right text-gray-200">
                      {{ s.price }}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-center mt-8">
          <a
            href="/search/Trending"
            class="text-xl font-bold xl:text-xl text-transparent text-gray-200 transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer text-shadow-white"
          >
            More Prompts <i class="fas fa-arrow-circle-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import FundSummary from "@/components/FundSummary.vue";
import Social from "@/components/Social.vue";
import LiveTx from "@/components/LiveTx.vue";
import Stats from "@/components/Stats.vue";
import OurTeam from "@/components/OurTeam.vue";
import Roadmap from "@/components/Roadmap.vue";
import Stake from "@/components/Stake.vue";

import { mapGetters, mapActions } from "vuex";

import NFTs from "@/api/NFTs";
import Prompt from "@/api/prompt";

import { POOL_ADDRESS, VERSION } from "../../../config";

import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";

export default {
  data() {
    return {
      version: VERSION,
      products: [],
      products_2: [],
      prompts: [],

      ref: this.$route.params.ref,
    };
  },
  components: {
    Social,
    LiveTx,
    Stats,
    OurTeam,
    Roadmap,
    Stake,
    ConnectWallet,
    StickFooter,
    FundSummary,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },

    async trendPrompts() {
      await Prompt.trendPrompts()
        .then((res) => {
          this.prompts = res.data.prompts;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    linkDetail(s) {
      if (s.price == "free") {
        return "/promptFree/" + s._id;
      } else {
        return "/prompt/" + s._id;
      }
    },
  },
  created() {
    this.trendPrompts();
    if (this.$route.params.ref) {
      localStorage.setItem("Ref", JSON.stringify(this.$route.params.ref));
    } else {
      localStorage.setItem("Ref", JSON.stringify(POOL_ADDRESS));
    }
  },
};
</script>
