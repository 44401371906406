<template>
  <div>
    <div>
      <ConnectWallet />
    </div>

    <div class="z-0 font-sans" v-if="getToken == 'false'">
      <!-- <Carousel :autoplay="3000" :wrap-around="true" class="bg-local">
        <Slide v-for="slide in slideShow" :key="slide">
          <div
            class="bg-cover h-screen w-screen bg-center"
            :style="'background-image: url(' + slide.img + ')'"
          >
            <div
              class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl animate-bounce"
            >
              <div style="margin-top: 50%">
                <h2 class="font-sans text-4xl font-bold sm:text-8xl ">
                  Nisa
                </h2>
              </div>
              <div>
                <h2 class="font-sans text-4xl font-bold sm:text-8xl ">
                  Between AI
                </h2>
              </div>
            </div>
          </div>
        </Slide>
      </Carousel> -->

      <!-- <div
        class="z-0 bg-fixed w-full bg-gradient-to-r from-purple-700 via-purple-500 bg-pink-400"
      >
        <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
          <div class="pt-10 justify-center">
            <div class="grid grid-cols-1 lg:grid-cols-2 pt-24 pb-24">
              <div class="flex flex-col items-center">
                <div class="">
                  <img src="@/assets/gif/nft.gif" />
                </div>
              </div>

              <div class="flex justify-center items-center text-gray-100">
                <div class="p-3 cursor-pointer text-center p-5">
                  <h2
                    class="font-sans text-4xl font-bold sm:text-6xl md:mx-auto"
                  >
                    Building Bridges <br />
                    Between AI and Humanity:
                  </h2>

                  <h3 href="#" class="text-xl font-bold lg:text-2xl mt-5">
                    Experience the Future of Digital Engagement with VirtualKOL,
                    Where AI Innovation Meets Authenticity, Empowering Content
                    Creators to Leave Their Indelible Mark on the Virtual
                    Landscape through NFT-Backed Copyright Protection
                  </h3>

                  <div class="mt-5">
                    <div class="flex">
                      <img
                        src="https://bscscan.com/images/svg/brands/bnb.svg?v=1.3"
                        class="h-8"
                      />
                      <div class="flex-none ml-1">
                        <h2
                          class="font-sans text-xl sm:text-2xl font-bold md:mx-auto"
                        >
                          Token Standard:
                        </h2>
                      </div>

                      <div class="flex-grow text-right">
                        <a href="https://etherscan.io/" target="_blank">
                          <b class="font-sans text-2xl sm:text-3xl underline"
                            >ERC-721</b
                          >
                        </a>
                      </div>
                    </div>

                    <div class="flex mt-3">
                      <img
                        src="https://bscscan.com/images/svg/brands/bnb.svg?v=1.3"
                        class="h-8"
                      />
                      <div class="flex-none ml-1">
                        <h2
                          class="font-sans text-xl sm:text-2xl font-bold md:mx-auto"
                        >
                          Chain:
                        </h2>
                      </div>

                      <div class="flex-grow text-right">
                        <a href="https://bscscan.com/" target="_blank">
                          <b class="font-sans text-2xl sm:text-3xl underline"
                            >BEP-20</b
                          >
                        </a>
                      </div>
                    </div>

                    <div class="flex mt-3">
                      <img
                        src="https://bscscan.com/images/svg/brands/bnb.svg?v=1.3"
                        class="h-8"
                      />
                      <div class="flex-none ml-1">
                        <h2
                          class="font-sans text-xl sm:text-2xl font-bold md:mx-auto"
                        >
                          Chain ID:
                        </h2>
                      </div>

                      <div class="flex-grow text-right">
                        <a href="https://bscscan.com/" target="_blank">
                          <b class="font-sans text-2xl sm:text-3xl underline"
                            >1 (0x1)</b
                          >
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <Free_Prompts class="mt-10" />

      <StickFooter />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";
import FundSummary from "@/components/FundSummary.vue";
import Social from "@/components/Social.vue";
import LiveTx from "@/components/LiveTx.vue";
import Stats from "@/components/Stats.vue";
import OurTeam from "@/components/OurTeam.vue";
import Roadmap from "@/components/Roadmap.vue";
import Stake from "@/components/Stake.vue";

import Featured_Prompts from "./home/Featured_Prompts.vue";
import Newest_Prompts from "./home/Newest_Prompts.vue";
import Trending_Prompts from "./home/Trending_Prompts.vue";
import Free_Prompts from "./home/Free_Prompts.vue";
import Sexy_Prompt from "./home/Sexy_Prompt.vue";

import Faq from "./faq.vue";

import { mapGetters, mapActions } from "vuex";

import NFTs from "@/api/NFTs";

import { POOL_ADDRESS, VERSION } from "../../config";

import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";

// import { products, main_product, products_2 } from "../../products";

export default {
  data() {
    return {
      version: VERSION,
      products: [],
      products_2: [],

      ref: this.$route.params.ref,
      slideShow: [
        {
          title: "Discover the World's Top",
          description: "Influencers and Virtual Humans",
          img: "https://ultimatewebsolutions.net/orabel/img/photo/32.jpg",
        },
        {
          title: "Discover the World's Top",
          description: "Influencers and Virtual Humans",
          img: "https://ultimatewebsolutions.net/orabel/img/photo/33.jpg",
        },
        {
          title: "Discover the World's Top",
          description: "Influencers and Virtual Humans",
          img: "https://ultimatewebsolutions.net/orabel/img/photo/31.jpg",
        },
      ],
    };
  },
  components: {
    Social,
    LiveTx,
    Stats,
    OurTeam,
    Roadmap,
    Stake,
    ConnectWallet,
    StickFooter,
    FundSummary,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Featured_Prompts,
    Trending_Prompts,
    Newest_Prompts,
    Faq,
    Free_Prompts,
    Sexy_Prompt,
  },
  computed: mapGetters(["getToken"]),

  methods: {},
  created() {},
};
</script>
<style scoped>
.video-wrapper {
  width: 100%;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
